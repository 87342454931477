import React from 'react';
import { useSearchParams } from 'react-router-dom';

import './App.css';

function Redirect() {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");
  if (url) {
    window.location.href = url;
  }
  return null;
}

export default Redirect;
